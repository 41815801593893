<template lang="pug">
  .home(v-if="data")
    #hero.hero.block-section
      .hero-content
        .wrapper.editor-content(v-html="data.acf.hero.content")
      img(:src="data.acf.hero.image.url")

    #about.about.p-t-100.p-b-100.block-section
      .wrapper.wrapper--small
        h2.text-center.m-b-50 About
        .editor-content(v-html="data.acf.about")

    #gallery.gallery.p-t-100.p-b-100(v-if="media.length").block-section
      light-box(ref="lightbox" :show-light-box="false" :media="media")
      .wrapper
        h2.text-center.m-b-50 Photo Gallery
      .images
        template(v-for="(i, index) in media")
          img(v-lazy="i.raw.sizes.medium" @click="openGallery(index)")

    #videos.videos.p-t-100.p-b-100.text-center.block-section
      .wrapper
        h2.m-b-50 Videography
        p Coming Soon

    #contact.contact.p-t-100.p-b-100.block-section
      .wrapper
        h2.text-center.m-b-50 Contact
        .flex.align-center.flex-wrap.justify-center
          a.m-l-15.m-r-15(v-if="data.acf.contact.facebook" :href="data.acf.contact.facebook" target="_blank")
            v-icon(name="brands/facebook" :scale="4")
          a.m-l-15.m-r-15(v-if="data.acf.contact.twitter" :href="data.acf.contact.twitter" target="_blank")
            v-icon(name="brands/twitter" :scale="4")
          a.m-l-15.m-r-15(v-if="data.acf.contact.linkedin" :href="data.acf.contact.linkedin" target="_blank")
            v-icon(name="brands/linkedin" :scale="4")
          a.m-l-15.m-r-15(v-if="data.acf.contact.youtube" :href="data.acf.contact.youtube" target="_blank")
            v-icon(name="brands/youtube" :scale="4")
          a.m-l-15.m-r-15(v-if="data.acf.contact.instagram" :href="data.acf.contact.instagram" target="_blank")
            v-icon(name="brands/instagram" :scale="4")
          a.m-l-15.m-r-15(v-if="data.acf.contact.email" :href="`mailto:${data.acf.contact.email}`")
            v-icon(name="envelope" :scale="4")
</template>

<script>
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

import LightBox from 'vue-image-lightbox'
import 'vue-awesome/icons'  
import Icon from 'vue-awesome/components/Icon'

export default {
  name: 'Home',
  components: {
    LightBox,
    'v-icon': Icon
  },
  data(){
    return {
      data: '',
      media: []
    }
  },
  methods: {
    openGallery(index){
      this.$refs.lightbox.showImage(index)
    }
  },
  beforeRouteEnter(to, from, next){
      fetch(`https://clairebechard.com/wp/wp-json/wp/v2/pages?slug=home`)
      .then(response => response.json())
      .then(data => {
        let page = data[0]
        next(vm => {
          vm.data = page
          vm.media = page.acf.image_gallery.map(i => {
            //examples:
            // { // For image
            //   thumb: 'http://example.com/thumb.jpg',
            //   src: 'http://example.com/image.jpg',
            //   caption: 'caption to display. receive <html> <b>tag</b>', // Optional
            //   srcset: '...' // Optional for displaying responsive images
            // },
            // { // For video
            //   thumb: 'https://s3-us-west-1.amazonaws.com/powr/defaults/image-slider2.jpg',
            //   sources: [
            //     {
            //       src: 'https://www.w3schools.com/html/mov_bbb.mp4',
            //       type: 'video/mp4'
            //     }
            //   ],
            //   type: "video",
            //   caption: '<h4>Monsters Inc.</h4>',
            //   width: 800, // required
            //   height: 600, // required
            //   autoplay: true //Optional to autoplay video when lightbox opens
            // }
            return {
              thumb: i.sizes.thumbnail,
              src: i.sizes.large,
              caption: i.alt,
              srcset: null,
              raw: i
            }
          })
        })
      })
    }
}
</script>
<style lang="sass">
  @import '@/sass/mixins'
  
  $black : #22242a

  .block-section
    &:nth-of-type(odd)
      background: $black
      color: white
      

  .hero
    min-height: 100vh
    position: relative
    display: flex
    align-items: center
    background: $black
    color: white    
    @include mobile
      flex-flow: column
    .hero-content
      width: 50%
      margin-top: auto
      margin-bottom: 100px
      @include mobile
        order: 2
        width: 100%
        margin-bottom: 0
        padding: 40px 0
    a
      text-decoration: none
    img
      width: 50%
      height: 100vh
      object-fit: cover
      @include mobile
        order: 1
        width: 100%
        height: auto

  .about
    p
      letter-spacing: 0.1em

  .gallery 
    .images
      columns: 3
      column-gap: 2px
      width: 1200px
      max-width: 100%
      margin: 0 auto
      @include mobile
        columns: 2
      img
        width: 100%
        margin-bottom: 2px
        display: flex

  .contact
</style>